.order-refundList-containier{
  .searchBox{
    display: flex;
    margin-bottom: 30px;
    .searchById{
      width: 60%;
      .ant-input, .ant-input-number{
        width: 270px;
      }
      .search-label{
        width: 140px;
        display: inline-block;
        text-align: right;
      }
      .search-label::before {
        display: inline-block;
        margin-right: 4px;
        color: #f5222d;
        font-size: 14px;
        font-family: SimSun, sans-serif;
        line-height: 1;
        content: '*';
      }
    }
    .searchByMon{
      width: auto;
    }
  }
}

.add-orderDirect-detail .orange-btn {
  background: #F29D1C;
  border-color: #F29D1C;
  color: #fff;
}
.add-orderDirect-detail .colrefund-item {
  .ant-input-number-disabled, .ant-input[disabled]{
    color: #989898;
  }
}

.add-orderDirect-detail .pro-detail-title {
  margin-bottom: 5px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.add-orderDirect-detail .pro-detail-smailtitle > .ant-descriptions-title {
  font-size: 14px;
  height: 34px;
}

.add-orderDirect-detail .step-wrap {
  padding: 20px 25% 20px 20px;
  box-sizing: border-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
}

.add-orderDirect-detail .info-box {
  padding-bottom: 20px;
  margin-bottom: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.add-orderDirect-detail .info-box .content {
  line-height: 1.5em;
  height: 1.5em;
}

.add-orderDirect-detail .info-box .info {
  color: #3490FD;
}

.add-orderDirect-detail .btns {
  text-align: right;
  margin: 30px 0;
}

.add-orderDirect-detail .btns button {
  margin-left: 10px;
}

.add-orderDirect-detail .step-wrap {
  border-bottom: none;
  padding: 20px 0 20px 0;
}

.add-orderDirect-detail .record-list {
  margin: 20px 0;
}

.add-orderDirect-detail .record-list-title {
  font-weight: bold;
  padding-bottom: 10px;
  font-size: 15px;
  margin-bottom: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.add-orderDirect-detail .blank-line {
  height: 10px;
  background: #f0f2f5;
  width: calc(100% + 40px);
  margin-left: -20px;
}

.add-orderDirect-detail .table-wrap {
  margin: 35px 0;
}

.add-orderDirect-detail .table-wrap .table-wrap-title {
  font-weight: bold;
  padding-bottom: 20px;
}

.add-orderDirect-detail .ask-info {
  margin: 10px 0;
}

.add-orderDirect-detail .ant-descriptions-item-content {
  color: #989898;
}

.add-orderDirect-detail .required-label{
  color: red
}
.add-orderDirect-detail .dItem-txt-box{
  height: 54px;
  width: 250px;
}
.add-orderDirect-detail .radio-in-label .ant-descriptions-item-colon::after{
  content: ''
}
.submit-btn-box{
  margin-bottom: 0 !important;
  margin-top: 20px !important;
  position: fixed !important;
  bottom: 75px;
  right: 80px;
}
.font-striking{
  color: #f5222d;
  font-weight: bold;
  font-size: 15px;
}
.font-striking-content{
  color: #f5222d !important;
  font-weight: bold !important;
  font-size: 14px !important;
}

.input-number-none {
  .ant-input-number-handler-wrap {
    display: none
  }
}